import React, { useState, useRef, useEffect } from 'react';

import { navigate } from 'gatsby';
import Utils from '../../../utils/utils';
import Api from '@/api';
import md5 from 'md5';
import Succsss from './Success'; //报名成功
import MyEnroll from './MyEnroll';
import { ConfigProvider, DatePicker } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

import Header from '../../../components/Header/Header';
import Banner from '../../../components/Banner/Banner';
import Footer from '../../../components/Footer/Footer';

import './FormMain.scss';

export default function Form(props) {
  const { form } = props;

  const [typeCode, setTypeCode] = useState('');
  const [codeTime, setCodeTime] = useState(0);
  const [intervalTime, setIntervalTime] = useState(null);

  let refs = [];
  let sendLoading = 0;
  const headerRefs = useRef();

  /**
   * 控制跳转
   * @param {*} type 
   * @param {*} params 
   */
  const changeType = (type, params) => {
    type = type === 'login' ? '' : type;
    navigate('/register/' + type + (params || ''));
  }

  const sendSmsCode = () => {
    if (codeTime)
      return;
    const phone = document.querySelector('#formDom [name=phone]').value;
    if (!phone)
      return Utils.message({ type: 'error', message: '请输入手机号码' });
    if (!valid(phone, 'mobile'))
      return Utils.message({ type: 'error', message: '请输入正确的手机号码' });

    setCodeTime(60);
    setIntervalTime(1000);

    Api.sendPhone({ phone, systemCode: 'adviser' }).then(res => {

    }).catch(err => { });
  }

  const toLogin = () => {
    Utils.clearCookies('match_token');
    Utils.clearCookies('adviser');
    Utils.clearCookies('adviser_phone');
    // window.document.cookie='match_token=;';
    //navigate('?type=login')
    changeType('login');
    props.changeUser && props.changeUser();
  }

  const resetPassword = () => {
    //const phoneNum=document.querySelector('#formDom [name=phone]').value;
    //const identifyCode=document.querySelector('#formDom [name=validateCode]').value;
    if (!sessionStorage.confirmForget)
      return;
    const { phone, validateCode } = JSON.parse(sessionStorage.confirmForget);
    const newPwd = document.querySelector('#formDom [name=newPassword]').value;
    const confirmPwd = document.querySelector('#formDom [name=password]').value;
    if (!newPwd || !confirmPwd) {
      Utils.message({ type: "error", message: '请输入密码' });
      return;
    }
    if (newPwd != confirmPwd) {
      Utils.message({ type: "error", message: '两次输入密码不一致' });
      return;
    }
    const systemCode = "adviser";
    const pwd = md5(newPwd);
    Api.resetPassword({ phoneNum: phone, identifyCode: validateCode, systemCode, pwd }).then(res => {
      Utils.message({ type: 'success', message: '密码已经修改，即将返回登录…', delay: 4000 });

      setTimeout(() => {
        toLogin();
      }, 4000)

    }).catch(err => {

    })
  }

  const valid = (data, key) => {
    let result = true;
    let msg = '';
    const forms = props.form;
    forms.list.map(item => {
      if (key && item.name != key) {
        return;
      }

      if (!item.name || (!item.valid && !item.maxLength && !item.minLength && !item.required))
        return;

      const value = data[item.name] || '';
      if (item.required) {
        if (!value || !Utils.valid(value)) {
          result = false;
          if (!msg)
            msg = item.placeholder;
          validBorder(item.name, true);
          return;
        } else {
          validBorder(item.name, false);
        }
      }

      const notValid = (item.required || value);

      if (notValid && item.minLength > value.length) {
        if (!msg)
          msg = item.label + '长度应不小于' + item.minLength + '位';
        result = false;
        //document.querySelector('#formDom [name='+item.name+']').style.borderColor='red';
        validBorder(item.name, true);
        return;
      } else {
        validBorder(item.name, false);
        //document.querySelector('#formDom [name='+item.name+']').style.borderColor='#DDDDDE';        
      }

      if (notValid && item.maxLength < value.length) {
        if (!msg)
          msg = item.label + '长度应不大于' + item.maxLength + '位';
        result = false;
        validBorder(item.name, true);
        //document.querySelector('#formDom [name='+item.name+']').style.borderColor='red';
        return;
      } else {
        validBorder(item.name, false);
        //document.querySelector('#formDom [name='+item.name+']').style.borderColor='#DDDDDE';        
      }

      if (notValid && item.valid) {
        for (let i = 0; i < item.valid.length; i++) {
          const validType = item.valid[i];
          if (!Utils.valid(value, validType)) {
            if (!msg)
              msg = item.label + '格式不正确';
            result = false;
            validBorder(item.name, true);
            break;
          } else {
            validBorder(item.name, false);
          }
        }
      }
    })
    if (msg) {
      Utils.message(msg);
    }
    return result;
  }

  const validBorder = (name, isError) => {
    const dom = document.querySelector('#formDom [name=' + name + ']')
    if (isError) {
      dom.className += ' error-valid';
    } else {
      dom.className = dom.className.replace(' error-valid', '');
    }
  }

  const validSmsCode = () => {
    const phone = document.querySelector('#formDom [name=phone]').value;
    const validateCode = document.querySelector('#formDom [name=validateCode]').value;
    Api.validatePhone({ phone, validateCode }).then(res => {
      sessionStorage.confirmForget = JSON.stringify({ phone, validateCode });
      changeType('confirm');
      //navigate('?type=confirm');
    }).catch(err => {
      return Utils.message({ type: 'error', message: err.message });

    })
  }

  const formButtonHander = (row) => {
    const urlQuery = Utils.urlQuery();
    const source = urlQuery['source'] || sessionStorage.sourceType || 0;
    const editId = urlQuery['editid'] || 0;
    let data = {};
    switch (row.type2) {
      case 'changeType':
        changeType(row.type);
        break;
      case 'nextStep':
        [].slice.call(document.querySelectorAll('#formDom [name]')).map(item => {
          data[item.name] = item.value;
        })
        if (!valid(data))
          return
        sessionStorage.tempData = JSON.stringify(data);
        changeType('enroll2', `?source=${source}&editid=${editId}`);

        break;
      case 'prevStep':
        changeType('enroll', `?source=${source}&editid=${editId}`);
        break;

      case 'login':
        data = {
          systemCode: "adviser",
          isRemember: 0,
        };
        [].slice.call(document.querySelectorAll('#formDom [name]')).map(item => {
          if(['checkbox'].includes(item.type)) {
            data[item.name] = item.checked;
          } else {
            data[item.name] = item.value;
          }
        })

        if (!valid(data)) return;
        data.pwd = data.pwd ? md5(data.pwd) : '';
        if(Object.keys(data).includes('isAgreed') &&  !data.isAgreed) {
         return Utils.message({ message: '请先阅读并同意《隐私政策》', type: 'error' });
        }
        Api.login(data).then(res => {
          Utils.setCookies('match_token', res.token);
          Utils.setCookies('adviser', data.userName);
          Utils.setCookies('adviser_phone', res.phone);
          props.changeUser && props.changeUser();
          Utils.message({ message: '您已成功登陆', type: 'success' });
          if (sessionStorage.oldType) {
            changeType(sessionStorage.oldType);
          }
          else {
            changeType('myEnroll');
          }
          if (sessionStorage.tempData)
            sessionStorage.tempData = '';
          sessionStorage.oldType = '';
        }).catch(err => {
          err.message && Utils.message({ message: err.message, type: 'error' });
        });
        break;
      case 'reset':
        validSmsCode();
        break;
      case 'resetConfirm':
        resetPassword();
        break;
      case 'enroll':
        {
          if (sendLoading) {
            return;
          }
          let data = {};
          if (sessionStorage.tempData)
            data = JSON.parse(sessionStorage.tempData);
          [].slice.call(document.querySelectorAll('#formDom [name]')).map(item => {
            data[item.name] = item.value;
          })
          if (!valid(data))
            return;
          if (editId && editId != '0')
            data.id = editId;
          if (sessionStorage.sourceType)
            data.sourceType = sessionStorage.sourceType;
          sendLoading = 1;
          Api.savelEnroll(data).then(res => {
            sessionStorage.tempData = '';
            //sessionStorage.sourceType='';
            //navigate('?type=success');
            changeType('success');
          }).catch(err => {
            sendLoading = 0;
            if (err.errCode == 603)
              toLogin();
          })
        }
        break;
    }
  }
  /**
   * 查询报名详情以修改信息
   * @param {*} id 
   */
  const enrollById=(id)=>{
    Api.enrollDetail({id}).then(data=>{
      [].slice.call(document.querySelectorAll('#formDom [name]')).map(dom=>{
        const domName=dom.name;
        if(data[domName]){
          dom.value=data[domName];
        }
      })
    }).catch(err=>{
      if(err.errCode==603){
        toLogin();
      }
    })
  }

  useEffect(()=>{
    const urlQuery=Utils.urlQuery();
    const source=urlQuery['source'] || sessionStorage.sourceType || 0;
    const editId=urlQuery['editid'] || 0;
  
    if(source)
      sessionStorage.sourceType=source;
    
    const token=Utils.getCookies('match_token');
    const type = window.location && window.location.pathname.replace('/register/','').replace('/','');
    setTypeCode(type);
    if(['login','forget','confirm'].indexOf(type)==-1  && !token){
      changeType('login');
      if(type)
        sessionStorage.oldType=type;
    }else if(type=='enroll2' && !(sessionStorage.tempData)){
      sessionStorage.oldType=type;
      changeType('enroll');
    } 
    if(type=='enroll' && sessionStorage.tempData){
      const data=JSON.parse(sessionStorage.tempData);
      [].slice.call(document.querySelectorAll('#formDom [name]')).map(dom=>{
        const domName=dom.name;
        if(data[domName])
          dom.value=data[domName];
      })
    }
    if(editId && editId!='0'){
      enrollById(editId);
    }

  },[])

  const tabs = (form && form.tabs && form.tabs.length > 0) ? form.tabs : [{ label: '登录', type: '', tabs: [] },{ label: '', type: 'hide', tabs: [] }];
  const typeName = (form && form.type) ? form.type : '';
  const formsClassName =form && form.className || '';
  return <div className="Form">
    <Header ref={headerRefs} />
    <Banner height='317' img='https://oss.5rs.me/oss/uploadfe/png/cbe7b52b04dfcb150a0585bec77cdda0.png?_=7802'></Banner>
    <div className={`FormMain ${formsClassName}`}>
      <div className={`tabs ${typeName + '-tabs'}`}>
        {tabs.map((item, index) => <span className={`${item.type === 'hide' ? 'hide-tab' : ''} white-hover ${typeCode === item.type ? 'now-hover' : ''}`} onClick={e => { item.type && changeType(item.type) }} key={index}>{item.label}</span>)}
      </div>

      <form id="formDom" className="content">
        {
          typeName === 'success' ? <Succsss changeType={changeType} />
            : typeName === 'myEnroll' ? <MyEnroll toLogin={toLogin} changeType={changeType} />
              // : <div className='is-end'>报名结束</div>
              :form && form.list.map((item, index) => (
                <div className={`form-item ${item.type || 'empty'}`} key={item.name || index}>
                  {(item.type == 'input' || item.type == 'textarea') && <label className={item.required ? 'required' : ''}>{item.label}</label>}
                  { item.type == 'input'
                    ? <span className={item.sms ? "sms-box" : ""}>
                      {
                        item.type2 === 'date' ?
                          <ConfigProvider locale={zhCN}>
                            <DatePicker ref={ref => refs[item.name] = ref} className={'andt-style'} locale={zhCN} name={item.name} onChange={e => { }} />
                          </ConfigProvider>
                          : item.type2 === 'password' ?
                            <input type="text" name={item.name}  onFocus={({target})=>{target.setAttribute('type','password')}} placeholder={item.placeholder}/>
                            : <input name={item.name} type={item.type2 ? item.type2 : ''} placeholder={item.placeholder} />
                      }
                      {item.sms && <a onClick={e => sendSmsCode()}>{intervalTime ? `重新发送(${codeTime})` : '发送验证码'}</a>}
                    </span>
                    : item.type == 'textarea'
                      ? <span><textarea name={item.name} placeholder={item.placeholder} ></textarea></span>
                      : item.type == 'button'
                        ? <button className="white-hover" type="button" onClick={e => { formButtonHander(item) }}>{item.label}</button>
                        : item.type == 'link'
                          ? <span className='link-wrap'>
                            { item.subtitle && item.subtitle}
                            <a onClick={e => { item.type && changeType(item.to) }}>{item.label}</a>
                          </span>  
                          : item.type == 'array'
                            ? item.list.map((item2, index2) => (
                              item2.type == 'button'
                                ? <button key={index2} className="white-hover" type="button" onClick={e => { formButtonHander(item2) }}>{item2.label}</button>
                                : <span key={index2}></span>
                            ))
                            : item.html
                              ? <p style={{ marginBottom: item.margin || '' }} className={item.className} dangerouslySetInnerHTML={{__html: item.label}}></p>
                              : <p style={{ marginBottom: item.margin || '' }} className={item.className}>{item.label}</p>
                  }
                </div>
              ))
        }
      </form>

    </div>
    <Footer />
  </div>

}