import React from 'react';
import Form from  '../components/Form';


export default function Success(props) {
    return  <div>
        <Form form={ FORMITEMLIST }/>
      </div>
}

//立即报名
const FORMITEMLIST =   {
  type:'success',tabs:[
    {label:'立即报名',type:'enroll'},
    {label:'我的报名',type:'myEnroll'}
  ],
  className:'success',list:[],
}